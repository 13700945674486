/*-- Chart --*/
.bb svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.bb path, .bb line {
  fill: none;
  stroke: #000; }

.bb text, .bb .bb-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.bb-legend-item-tile,
.bb-xgrid-focus,
.bb-ygrid-focus,
.bb-ygrid,
.bb-event-rect,
.bb-bars path {
  shape-rendering: crispEdges; }

.bb-chart-arc .bb-gauge-value {
  fill: #000; }

.bb-chart-arc path {
  stroke: #fff; }

.bb-chart-arc rect {
  stroke: #fff;
  stroke-width: 1; }

.bb-chart-arc text {
  fill: #fff;
  font-size: 13px; }

/*-- Axis --*/
.bb-axis {
  shape-rendering: crispEdges; }

/*-- Grid --*/
.bb-grid line {
  stroke: #aaa; }

.bb-grid text {
  fill: #aaa; }

.bb-xgrid, .bb-ygrid {
  stroke-dasharray: 3 3; }

/*-- Text on Chart --*/
.bb-text.bb-empty {
  fill: #808080;
  font-size: 2em; }

/*-- Line --*/
.bb-line {
  stroke-width: 1px; }

/*-- Point --*/
.bb-circle._expanded_ {
  stroke-width: 1px;
  stroke: white; }

.bb-selected-circle {
  fill: white;
  stroke-width: 2px; }

/*-- Bar --*/
.bb-bar {
  stroke-width: 0; }
  .bb-bar._expanded_ {
    fill-opacity: 0.75; }

/*-- Focus --*/
.bb-target.bb-focused {
  opacity: 1; }

.bb-target.bb-focused path.bb-line, .bb-target.bb-focused path.bb-step {
  stroke-width: 2px; }

.bb-target.bb-defocused {
  opacity: 0.3 !important; }
  .bb-target.bb-defocused .text-overlapping {
    opacity: .05 !important; }

/*-- Region --*/
.bb-region {
  fill: steelblue;
  fill-opacity: .1; }

/*-- Zoom region --*/
.bb-zoom-brush {
  fill-opacity: .1; }

/*-- Brush --*/
.bb-brush .extent {
  fill-opacity: .1; }

/*-- Select - Drag --*/
/*-- Legend --*/
.bb-legend-item {
  font-size: 12px;
  user-select: none; }

.bb-legend-item-hidden {
  opacity: 0.15; }

.bb-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1; }

/*-- Title --*/
.bb-title {
  font: 14px sans-serif; }

/*-- Tooltip --*/
.bb-tooltip-container {
  z-index: 10;
  user-select: none; }

.bb-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  opacity: 0.9;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777; }
  .bb-tooltip tr {
    border: 1px solid #CCC; }
  .bb-tooltip th {
    background-color: #aaa;
    font-size: 14px;
    padding: 2px 5px;
    text-align: left;
    color: #FFF; }
  .bb-tooltip td {
    font-size: 13px;
    padding: 3px 6px;
    background-color: #fff;
    border-left: 1px dotted #999; }
    .bb-tooltip td > span, .bb-tooltip td > svg {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 6px; }
  .bb-tooltip.value {
    text-align: right; }

/*-- Area --*/
.bb-area {
  stroke-width: 0;
  opacity: 0.2; }

/*-- Arc --*/
.bb-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em; }

.bb-chart-arcs-gauge-title {
  dominant-baseline: middle;
  font-size: 2.7em; }

.bb-chart-arcs .bb-chart-arcs-background {
  fill: #e0e0e0;
  stroke: #fff; }

.bb-chart-arcs .bb-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px; }

.bb-chart-arcs .bb-chart-arcs-gauge-max {
  fill: #777; }

.bb-chart-arcs .bb-chart-arcs-gauge-min {
  fill: #777; }

/*-- Radar --*/
.bb-chart-radars .bb-levels polygon {
  fill: none;
  stroke: #848282;
  stroke-width: .5px; }

.bb-chart-radars .bb-levels text {
  fill: #848282; }

.bb-chart-radars .bb-axis line {
  stroke: #848282;
  stroke-width: .5px; }

.bb-chart-radars .bb-axis text {
  font-size: 1.15em;
  cursor: default; }

.bb-chart-radars .bb-shapes polygon {
  fill-opacity: .2;
  stroke-width: 1px; }

/*-- Button --*/
.bb-button {
  position: absolute;
  top: 10px;
  right: 10px; }
  .bb-button .bb-zoom-reset {
    font-size: 11px;
    border: solid 1px #ccc;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer; }
